import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from "../../environments/environment";
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: string;
  email: string;
  message: string;
  nameError: boolean = false;
  emailError: boolean = false;
  messageError: boolean = false;
  email_api: string = environment.email_api;
  messageTitle: string;
  messageBody: string;
  messageType: string;
  myRecaptcha: boolean;
  
  @ViewChild('swl') private swl: SwalComponent;

  constructor(private http:HttpClient,private router: Router) {window.scrollTo(0, 0);}
  
  ngOnInit() {
  }
  submit = function () {
    this.nameError = false;
    this.emailError = false;
    this.messageError = false;

    if (!this.name) {
      this.nameError = true;
    }
    if ((!this.email) || (!this.validateEmail(this.email))) {
      this.emailError = true;
    }
    if (!this.message) {
      this.messageError = true;
    }
    if (this.myRecaptcha) {
      if (this.nameError == false && this.emailError == false && this.messageError == false) {

        var formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('message', this.message);
        // formData.append('myRecaptcha', this.myRecaptcha);

        this.http.post(this.email_api, formData).subscribe((res: any) => {
          if (res.status == 'success') {
            this.messageType = 'success';
            this.messageTitle = 'Success!';
            this.messageBody = 'Thank you. We\'ll get back to you soon.';

            this.name = '';
            this.email = '';
            this.message = '';
            // this.myRecaptcha='';
            
            setTimeout(() => {
              this.swl.show();

              setTimeout(() => {
                this.router.navigateByUrl('/home', {skipLocationChange: true}).then(()=>
                this.router.navigate(["/contact"]));
              }, 2000);
            }, 500);
            
          } else {
            this.messageType = 'warning';
            this.messageTitle = 'Sorry!';
            this.messageBody = 'Something happened. Please try again later.';

            this.name = '';
            this.email = '';
            this.message = '';
            // this.myRecaptcha='';
          }
          setTimeout(() => {
            this.swl.show();
          }, 500);
          
        });
      }else{
        this.messageType = 'warning';
        this.messageTitle = 'Sorry!';
        this.messageBody = 'Sending failed!';

        setTimeout(() => {
          this.swl.show();
        }, 500);
      }
    } else {
      this.messageType = 'warning';
      this.messageTitle = 'Sorry!';
      this.messageBody = 'Please check your Google reCaptcha form.';

      setTimeout(() => {
        this.swl.show();
      }, 500);
    }

    

  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
}


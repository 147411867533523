import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AppComponent } from './app.component';
import { FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { OurWorkComponent } from './our-work/our-work.component';
import { ClientComponent } from './client/client.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { RouterModule, Routes } from '@angular/router';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { SliderComponent } from './slider/slider.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    OurWorkComponent,
    ClientComponent,
    BlogComponent,
    ContactComponent,
    SliderComponent
  ],
  imports: [
    BrowserModule,
    AngularFontAwesomeModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    RecaptchaModule.forRoot({
      siteKey: '6Lcod20UAAAAAKDxej1428amw3F7X9x73fG9JXCj',
    }),
    RouterModule.forRoot([
    {path:'', component:HomeComponent},
    {path:'home', component:HomeComponent},
    {path:'about', component:AboutComponent},
    {path:'our-work', component:OurWorkComponent},
    {path:'our-work/:catname', component:SliderComponent},
    {path:'client', component:ClientComponent},
    {path:'news', component:BlogComponent},
    {path:'contact', component:ContactComponent},
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

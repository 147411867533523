import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from "../../environments/environment";

declare var $;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    email: string;
    emailError: boolean = false;
    email_api: string = environment.email_api;
    emailchimp_api: string = environment.mailchimp_api;
    

  
    constructor(private http:HttpClient) {}

    ngOnInit() {
    }

    subscription() {
      this.emailError = false;
  
      if (!this.email || !this.validateEmail(this.email)) {
        this.emailError = true;
      }
      
        if (this.emailError == false) {
          console.log(this.email);
          var formData = new FormData();
          formData.append('email', this.email);
  
          this.http.post(this.emailchimp_api, formData).subscribe((res: any) => {
            if (res.status == 'success') {
              $('#success-subs').show();
              setTimeout(() => {
                location.reload();
            }, 5000);
            } 
            else{
              $('#error-subs').show();
              setTimeout(() => {
                location.reload();
            }, 5000);
            }
          });
        }
    }
    
    validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  catName: String;
  workImages: any;
  constructor(private route: ActivatedRoute, private http: HttpClient) {
    this.route.params.subscribe(params => {
      this.catName = params['catname'];
      this.loadData(this.catName);
    });
  }

  ngOnInit() {
   
  }

  loadData(category) {
    this.http.get('assets/data/work.json').subscribe((data: any) => {
     this.workImages = data[category];
   });
  }


}

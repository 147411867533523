import { Component, OnInit } from '@angular/core';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-our-work',
  templateUrl: './our-work.component.html',
  styleUrls: ['./our-work.component.css']
})
export class OurWorkComponent implements OnInit {

  constructor() { window.scrollTo(0, 0);}

  ngOnInit() {
  }

  openModel(index){
    $('#rave-works-model').modal('show');
    $('#rave-works-model .carousel-inner .carousel-item').removeClass('active');
    $('#rave-works-model .carousel-inner .carousel-item:nth-child('+index+')').addClass('active');

  }



  OutdoorModel(index){
  $('#outdoor-model').modal('show');
  $('#outdoor-model .carousel-inner .carousel-item').removeClass('active');
  $('#outdoor-model .carousel-inner .carousel-item:nth-child('+index+')').addClass('active');

}
PackingModel(index){
  $('#packing-model').modal('show');
  $('#packing-model .carousel-inner .carousel-item').removeClass('active');
  $('#packing-model .carousel-inner .carousel-item:nth-child('+index+')').addClass('active');

}

StationeryModel(index){
  $('#Stationery-model').modal('show');
  $('#Stationery-model .carousel-inner .carousel-item').removeClass('active');
  $('#Stationery-model .carousel-inner .carousel-item:nth-child('+index+')').addClass('active');

}
VehicleModel(index){
  $('#vehicle-model').modal('show');
  $('#vehicle-model .carousel-inner .carousel-item').removeClass('active');
  $('#vehicle-model .carousel-inner .carousel-item:nth-child('+index+')').addClass('active');

}

}

